import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import * as PlaylistStyling from "./Playlist.module.scss";

const Playlist = ({ block }) => {
  const title = block.title;
  const cover = block.cover;
  const structuredText = block.description;
  const url = block.url;

  return (
    <a
      href={url}
      target="_blank"
      className={`row r-middle ${PlaylistStyling.playlistWrapper}`}
    >
      <div className={`col s-12 sm-8 l-6`}>
        <GatsbyImage className={PlaylistStyling.image} image={cover.gatsbyImageData} alt={cover.alt} />
      </div>
      <div className="col s-12 l-6">
        <p className="text5 mt-5">{title}</p>
        <StructuredTextRenderer
          data={structuredText}
          isParagraphClassName="mb-5 subtle"
          isHeadingClassName="mb-5"
          isListItemClassName="subtle"
        />
      </div>
    </a>
  );
};
export default Playlist;

Playlist.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.object,
    cover: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};
